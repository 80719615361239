<template>
  <div>
    <b-row>
      <b-col cols="12">
        <form-repeater-detail
          v-if="formShow === true"
          @refetch-empty-data="refetchEmptyData"
        />
        <empty-page
          v-else
          :empty-kind-page="'form-manager'"
          :header-string="'No Form found'"
          :content-string="'It seems, you have no forms yet. Let’s create one and embed it to Landing Page.'"
          :link-to-name="'form-managers-create'"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import EmptyPage from '@/views/menus/EmptyPage.vue'
import FormRepeaterDetail from './FormRepeaterDetail.vue'

export default {
  components: {
    BRow,
    BCol,

    FormRepeaterDetail,
    EmptyPage,
  },
  data() {
    return {
      formShow: true,
    }
  },
  methods: {
    refetchEmptyData() {
      this.formShow = false
    },
  },
}
</script>
