export default
[
  { text: 'Text', value: 'text' },
  { text: 'Pharagraph', value: 'textarea' },
  { text: 'Number', value: 'number' },
  { text: 'Email', value: 'email' },
  { text: 'Select', value: 'select' },
  { text: 'Date', value: 'date' },
  { text: 'Checkbox', value: 'checkbox' },
  { text: 'Radio', value: 'radio' },
]
