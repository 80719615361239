<template>
  <b-card
    :title="(this.$route.params.id !== undefined) ? 'Edit Form' :'Create Form'"
  >
    <b-form
      ref="form"
      :style="{height: trHeight, 'overflow-y':'scroll'}"
      class="repeater-form"
      @submit.prevent="submitForm"
    >
      <div>
        <b-row class="d-flex justify-content-between align-items-center">
          <!-- Title -->
          <b-col md="4">
            <b-form-group
              label="Form Title (Form title will not be displayed for public)"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="formData.title"
                type="text"
                :state="errorData.title === '' ? null:false"
                placeholder=""
              />
              <small class="text-danger">{{ errorData.title }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <!-- Row Loop -->
        <div>
          <span class="demo-inline-spacing mb-1">
            <h5>Form Detail</h5>
          </span>
          <div
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            class="mt-2"
          >
            <b-row
              ref="row"
              class="mr-0"
            >
              <!-- Label -->
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Label Input Name"
                  label-for="label"
                >
                  <b-form-input
                    v-model="formData.labels[index]"
                    type="text"
                    :state="errorData.labels === '' ? null:false"
                  />
                  <small class="text-danger">{{ errorData.labels }}</small>
                </b-form-group>
              </b-col>

              <!-- Type -->
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Input Type"
                  label-for="input_type"
                >
                  <b-form-select
                    v-model="formData.types[index]"
                    :options="inputTypes"
                    :state="errorData.types === '' ? null:false"
                  />
                  <small class="text-danger">{{ errorData.types }}</small>
                </b-form-group>
              </b-col>

              <!-- Detail Options -->
              <b-col
                cols="12"
                md="3"
                :class="formData.types[index] == 'checkbox' || formData.types[index] == 'select' || formData.types[index] == 'radio' ? 'd-block' : 'd-none'"
              >
                <b-form-group
                  label="Detail Options"
                  label-for="details"
                >
                  <b-form-input
                    v-model="formData.options[index]"
                    type="text"
                    placeholder="Separate with commas, ex: item1,item2,item3"
                    :state="errorData.options === '' ? null:false"
                  />
                  <small class="text-danger">{{ errorData.options }}</small>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-flex">
              <!-- Required -->
              <div class="mr-2">
                <b-form-group>
                  <b-form-checkbox
                    v-model="formData.required[index]"
                    :value="true"
                  >
                    Required
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <!-- Remove Button -->
              <div>
                <b-link
                  v-b-tooltip.hover
                  href="#"
                  class="btn-icon mt-0 mt-md-2 text-danger"
                  @click="removeItem(index)"
                >
                  Delete
                </b-link>
              </div>
            </div>
            <hr>
          </div>
          <!-- Add new field -->
          <div>
            <b-button
              class="mb-3 mt-1"
              variant="outline-primary"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add new field</span>
            </b-button>
          </div>
        </div>

      </div>
      <div>
        <!-- Submit Btn -->
        <b-col
          md="2"
          xs="6"
          class="d-flex justify-content-start pl-0"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="submit"
            variant="primary"
            :disabled="disableBtn"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <span class="align-middle">
              {{ this.$route.params.id !== undefined ? 'Update' : 'Publish' }}
            </span>
          </b-button>
        </b-col>
      </div>
    </b-form>
  </b-card>

</template>

<script>
import {
  BLink, BCard, BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormCheckbox, BFormSelect, VBTooltip,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InputTypes from '../../../components/custom/inputTypes'

export default {
  components: {
    BLink,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    // vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      inputTypes: InputTypes,
      selected: null,
      disableBtn: false,
      formData: {
        slug: null,
        title: null,
        labels: [],
        types: [],
        required: [],
        options: [],
      },
      errorData: {
        slug: '',
        title: '',
        labels: '',
        types: '',
        required: '',
        options: '',
      },
      selfData: null,
    }
  },
  computed: {
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
  mounted() {
    this.initTrHeight()
    this.getSelf()
    if (this.$route.params.id !== undefined){
      this.getFormData()
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getSelf() {
      store.dispatch('app-user/getSelf')
        .then(response => {
          this.selfData = response.data
        })
    },
    getFormData() {
      store.dispatch('menu-form/getForm', { id: this.$route.params.id })
        .then(response => {
          this.formData = response.data.data
          this.formData.labels = JSON.parse(this.formData.labels)
          this.formData.options = JSON.parse(this.formData.options)
          this.formData.required = JSON.parse(this.formData.required)
          this.formData.types = JSON.parse(this.formData.types)
          this.formData.length = this.formData.labels.length

          // open all collapsed form element
          if (this.formData.length > 1){
            let i = this.formData.length
            while (i > 1) {
              this.repeateAgain()
              i -= 1
            }
          }
        })
        .catch(error => {
          if (error.response.status !== 200){
            this.$emit('refetch-empty-data', false)
          }
        })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    reinitializeErrorData() {
      this.errorData = {
        slug: '',
        title: '',
        labels: '',
        types: '',
        required: '',
        options: '',
      }
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    showErrorMessages(error) {
      if (error.response.data.message !== undefined){
        Object.entries(error.response.data.message).forEach(([key, value]) => {
          const [errMessage] = value
          if (key === 'title'){
            this.errorData.title = errMessage
          }
          if (key === 'type'){
            this.errorData.types = value
          }
          if (key === 'label'){
            this.errorData.labels = value
          }
          if (key === 'options'){
            this.errorData.options = value
          }
        })
      }
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error has occured',
          icon: 'XIcon',
          variant: 'danger',
          text: error.response.data.customMessage !== undefined ? error.response.data.customMessage : 'try to check all form input !',
        },
      })
      this.disableBtn = false
    },
    submitForm() {
      this.disableBtn = true
      this.formData.length = this.items.length
      this.formData.user_id = this.selfData.id
      // remove error message
      this.reinitializeErrorData()
      if (this.$route.params.id === undefined){
        store.dispatch('menu-form/addForm', this.formData)
          .then(response => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.disableBtn = false
            this.$router.push({ name: 'form-managers-list' })
          })
          .catch(error => {
            if (error.response !== undefined){
              this.showErrorMessages(error)
            } else {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'Something error when create form !',
                },
              })
            }
          })
      } else {
        store.dispatch('menu-form/updateForm', {
          id: this.$route.params.id,
          data: this.formData,
        })
          .then(response => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.disableBtn = false
            this.$router.push({ name: 'form-managers-list' })
          })
          .catch(error => {
            if (error.response !== undefined){
              this.showErrorMessages(error)
            } else {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'Something error when update this form !',
                },
              })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
